<template>
  <v-container fluid :class="[$vuetify.breakpoint.mobile ? '' : 'bg-fotos']">

    <!-- this is for desktop view -->
    <v-row no-gutters
      :style="{'min-height': (items.length < 2) ? `${2 * espacioFotos}px` : undefined}"
      v-if="!$vuetify.breakpoint.mobile">

      <v-col 
        cols="2" 
        :offset="i == 2 ? 0 :1" 
        class="foto-container" 
        v-for="(item, i) in items" :key="`${item.nombre}-${i}`">
        <tooltip-content
          :nombre="item.fullNombre"
          :rol="item.rol"
          :descripcion="item.descripcion"
          :socials="item.socials"
          :activator="$refs[`foto${item.nombre}-${i}`] ? ($refs[`foto${item.nombre}-${i}`][0] ? $refs[`foto${item.nombre}-${i}`][0] : $refs[`foto${item.nombre}-${i}`]) : ''" />
          <div :ref="`foto${item.nombre}-${i}`" class="pointer">
            <v-img 
              :src="item.foto" 
              :class="[i < 2 ? 'foto-up' : 'foto-down']" />
            <div class="d-block" :style="`height: ${espacioFotos}px`" v-if="i >= 2"></div>
            <v-img src="assets/tokenfit-equipo-border.svg" />
            <p class="d-block title pt-3">{{item.nombre}}<br><span class="body-1">{{item.rol}}</span></p>
          </div>
      </v-col>
    </v-row>
    <!------------------------------------------------------>

    <!-- this is for mobile view -->
    <!------------------------------------------------------>
    <v-row v-else>
      <v-container>
        <v-row>
          <v-col 
            cols="5" :offset="i % 2 == 1 ? '' : '1'"
            v-for="(item, i) in items" :key="`${item.nombre}-${i}`">
            <tooltip-content
              :nombre="item.fullNombre"
              :rol="item.rol"
              :descripcion="item.descripcion"
              :socials="item.socials"
              :activator="$refs[`foto${item.nombre}-${i}`] ? ($refs[`foto${item.nombre}-${i}`][0] ? $refs[`foto${item.nombre}-${i}`][0] : $refs[`foto${item.nombre}-${i}`]) : ''" />
              <div :ref="`foto${item.nombre}-${i}`" class="pointer foto-container d-flex align-content-center align-items-center flex-column">
                <v-img 
                  :src="item.foto" 
                  :class="[i % 2 == 0 ? 'foto-up-mobile' : 'foto-down-mobile']" width="29vw"/>
                <div class="d-block" :style="`height: ${espacioFotosMobile}vw`" v-if="i % 2 == 1"></div>
                <v-img src="assets/tokenfit-equipo-border.svg" contain max-width="32vw"/>
                <p class="d-block title pt-3" style="width: 32vw;">{{item.nombre}}<br><span class="body-1">{{item.rol}}</span></p>
              </div>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
// Este componente no es tan flexible. En principio acepta máximo hasta 4 items
// En caso que sea menor se omitirá desde el final hacia adelante
export default {
  name: 'RowFotos',
  components: {
    TooltipContent: () => import('@/views/components/TooltipContent')
  },
  props: {
    espacioFotos: {
      type: Number,
      default: 185 // this is in px
    },
    espacioFotosMobile: {
      type: Number,
      default: 17 // this is in vw
    },
    items: {
      type: Array,
      default: () => []
    }
  },

}
</script>

<style>
.bg-fotos {
  background-image: url('../../../public/assets/tokenfit-equipo-back-01.svg'), url('../../../public/assets/tokenfit-equipo-back-02.svg');
  background-size: 50%;
  background-position-x: 0, 100%;
  background-position-y: 94px;
}
.foto-container {
  position: relative;
}
.foto-up {
  position: absolute;
  top: .5em;
  left: .5em;
}
.foto-up-mobile {
  position: absolute;
  top: 1.5vw;
  left: 1.5vw;
}
.foto-down {
  position: absolute;
  bottom: 6em;
  left: .5em;
}
.foto-down-mobile {
  position: absolute;
  top: 18.5vw;
  left: 1.5vw;
}
</style>